import React, { FunctionComponent } from 'react'
import Main from "../Main/Main"
import PasswordFormContainer from "./PasswordFormContainer"
import Details from "../Details/Details"
import ButtonLink from "../ButtonLink"
import { RouteComponentProps } from "react-router"
import { routes } from "../../shared/constants"

const PasswordFormWrapper: FunctionComponent<RouteComponentProps> = (props: RouteComponentProps) => {
    return (
        <Main>
            <PasswordFormContainer {...props} />
            <Details>
                <ButtonLink to={routes.FORGOT_PASSWORD}>I forgot my Password</ButtonLink>
            </Details>
        </Main>
    )
}

export default PasswordFormWrapper
