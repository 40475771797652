import { connect } from 'react-redux';
import UsernameForm from './UsernameForm';
import { MyThunkDispatch, SingleLoginStore } from '../../store/rootStore';
import { submitUsername, usernameError } from '../../store/login/actions';
import {
  fetchWorkspacesError,
  loadWorkspaces,
  setSelectedWorkspace,
} from '../../store/workspace/actions';
import { routes } from '../../shared/constants';
import { Workspace } from '../../store/workspace/types';
import {
  isSingleSignOnUser,
  routeToDifferenceKipsuInstance,
  routeToOIDCRedirect,
  shouldRouteToDifferentInstance,
} from '../../helpers/workspaceRouting';
import { RouteComponentProps } from 'react-router';
import { oidcSetConfig } from '../../store/oidc/actions';

const mapStateToProps = (state: SingleLoginStore) => ({
  isLoading: state.workspace.isLoading,
  username: state.login.username,
  error: state.workspace.error || state.login.usernameError || state.oidc.error,
});

export interface OnSubmitParams {
  username: string;
  router: RouteComponentProps;
}

const mapDispatchToProps = (dispatch: MyThunkDispatch) => {
  return {
    onSubmit: ({ username, router }: OnSubmitParams) => {
      dispatch(loadWorkspaces(username))
        .then((workspaces: Workspace[]) => {
          dispatch(submitUsername(username));

          if (workspaces.length > 1) {
            router.history.push(routes.SELECT_WORKSPACE);
          } else if (workspaces.length === 1) {
            const workspace: Workspace = workspaces[0];
            dispatch(setSelectedWorkspace(workspace));

            if (shouldRouteToDifferentInstance(workspace)) {
              routeToDifferenceKipsuInstance(workspace, username);
            } else if (isSingleSignOnUser(workspace)) {
              dispatch(oidcSetConfig(workspace.identityProvider));
              routeToOIDCRedirect(workspace);
            } else {
              router.history.push(routes.PASSWORD_FORM);
            }
          } else {
            dispatch(fetchWorkspacesError('User not found.'));
          }
        })
        .catch((e) => {
          dispatch(fetchWorkspacesError(e.message));
        });
    },
    onError: (error: string) => {
      dispatch(usernameError(error));
    },
    clearError: () => {
      dispatch(fetchWorkspacesError(''));
      dispatch(usernameError(''));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsernameForm);
