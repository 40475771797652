import React, { FormEvent, FunctionComponent } from 'react'
import styles from './FormWrapper.module.scss'

interface Params {
    onSubmit: (e: FormEvent<HTMLFormElement>) => void
}

const FormWrapper: FunctionComponent<Params> = ({ children, onSubmit }) => (
    <form
        className={styles.form}
        onSubmit={(e: FormEvent<HTMLFormElement>) => onSubmit(e)}
    >{children}</form>
)

export default FormWrapper
