import { Workspace } from "../store/workspace/types"
import { routes } from "../shared/constants"
import { signInRedirect } from "./ocid"

export const KIPSU_SELECTED_WORKSPACE = 'KIPSU_SELECTED_WORKSPACE'

export const routeToOIDCRedirect = (workspace: Workspace): void => {
    if (workspace.identityProvider) {
        localStorage.setItem(KIPSU_SELECTED_WORKSPACE, JSON.stringify(workspace))
        signInRedirect({...workspace.identityProvider})
    }
}

export const shouldRouteToDifferentInstance = (workspace: Workspace): boolean => {
    const url: URL = new URL(workspace.webUrl)
    return url.host !== window.location.host
}

export const routeToDifferenceKipsuInstance = (workspace: Workspace, username: string) => {
    window.location.href = encodeURI(`${workspace.webUrl}${routes.REDIRECT}?username=${username}`)
}

export const isSingleSignOnUser = (workspace: Workspace) => {
    return workspace.identityProvider != null
}
