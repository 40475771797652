import React, { FunctionComponent } from "react"
import './Loader.scss'

interface Props {
    center?: boolean
    isLoading?: boolean
}

const Loader: FunctionComponent<Props> = (props) => (
    props.isLoading
        ? (
            <div className={props.center ? 'k-loader--center' : 'k-loader'}>
                <div className="k-loader__double-ring">
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
        : (<div style={{ height: '50px', width: '50px' }} />)
)

export default Loader
