import {
    OIDC_REDIRECT,
    OIDC_SET_CONFIG,
    OIDC_SUBMIT_FAILURE,
    OIDC_SUBMIT_REQUEST,
    OIDCActionTypes,
    OIDCConfig,
    LoginResponse
} from "./types"

export interface OIDCState {
    isLoading: boolean
    error: string
    config: OIDCConfig | null
    user: LoginResponse | null
}

const initialState: OIDCState = {
    isLoading: false,
    error: '',
    config: null,
    user: null
}

export default function oidc(state: OIDCState = initialState, action: OIDCActionTypes) {
    switch (action.type) {
        case OIDC_SET_CONFIG:
            return { ...state, config: action.config, isLoading: true }
        case OIDC_REDIRECT:
            return { ...state, isLoading: true }
        case OIDC_SUBMIT_REQUEST:
            return { ...state, isLoading: true }
        case OIDC_SUBMIT_FAILURE:
            return { ...state, error: action.error, isLoading: false }
        default:
            return state
    }
}
