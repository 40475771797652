import React, { Component, FormEvent } from 'react'
import FormWrapper from "../Form/FormWrapper"
import KInput from "../KInput/KInput"
import KButton, { KButtonTypes } from "../KButton/KButton"
import FormHeader from "../Form/FormHeader"
import { PasswordOnSubmitParams } from "./PasswordFormContainer"
import { Link, RouteComponentProps } from "react-router-dom"
import styles from './PasswordForm.module.scss'
import { routes } from "../../shared/constants"
import Loader from "../Loader/Loader"
import { Workspace } from "../../store/workspace/types"
import ReactTooltip from "react-tooltip";

interface PasswordFormProps {
    clearError: () => void,
    error: string,
    isLoading: boolean,
    multipleWorkspaces: boolean,
    onError: (error: string) => void,
    onSubmit: (payload: PasswordOnSubmitParams) => void,
    username: string,
    selectedWorkspace: Workspace | null
}

interface State {
    input: string,
    isPasswordShown: boolean
}

class PasswordForm extends Component<PasswordFormProps & RouteComponentProps, State> {

    public constructor(props: PasswordFormProps & RouteComponentProps) {
        super(props)

        this.state = {
            input: '',
            isPasswordShown: false
        }
    }

    togglePasswordVisibility =() => {
        const {isPasswordShown} = this.state;
        this.setState({isPasswordShown: !isPasswordShown});
    }

    public render() {
        const {isPasswordShown} = this.state;
        return (
            <FormWrapper
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault()
                    if (this.state.input == null || !this.state.input.trim()) {
                        this.props.onError('Please enter a password')
                        return
                    }
                    this.props.clearError()
                    this.props.onSubmit({
                        password: this.state.input,
                        username: this.props.username,
                        router: this.props,
                        selectedWorkspace: this.props.selectedWorkspace

                    })
                }}
            >
                <FormHeader />
                <div className={styles.passwordFieldWrapper}>
                    <KInput
                        label="Password"
                        id="password"
                        error={this.props.error}
                        type={isPasswordShown ? "text" : "password"}
                        subtext={`Logging in as ${this.props.username}`}
                        value={this.state.input}
                        onChange={(value: string) => {
                            this.setState({
                                input: value
                            })
                        }}
                        autocomplete="current-password"
                    />
                    <i className={`kip ${isPasswordShown ? "kip-hide" : "kip-show"}`}
                       onClick={this.togglePasswordVisibility}
                       data-tip data-for={isPasswordShown ? "hide-password" : "show-password"}
                    />
                    <ReactTooltip id={isPasswordShown ? "hide-password" : "show-password"}
                                  place="top"
                                  effect="solid">{isPasswordShown ? "Hide password." : "Show password."}</ReactTooltip>
                </div>
                <footer className={styles.footer}>
                    <Link to={this.props.multipleWorkspaces ? routes.SELECT_WORKSPACE : routes.USERNAME_FORM}
                          aria-label={this.props.multipleWorkspaces ? "Select a different Workspace" : "Return to username form"}>
                        <span className={styles.background} aria-hidden="true"> </span>
                        <span className={styles.link}>Back</span>
                    </Link>
                    <div className={styles.submitContainer}>
                        <Loader isLoading={this.props.isLoading} />
                        <KButton type={KButtonTypes.SUBMIT}>Login</KButton>
                    </div>
                </footer>
            </FormWrapper>
        )
    }
}

export default PasswordForm
