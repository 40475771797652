export const routes = {
    BASE: '/',
    USERNAME_FORM: '/acct/auth/login/username',
    PASSWORD_FORM: '/acct/auth/login/password',
    SSO_FORM: '/acct/auth/login/sso',
    SELECT_WORKSPACE: '/acct/auth/login/workspaces',
    OIDC_CALLBACK: '/acct/auth/login/callback',
    OIDC_ERROR_PAGE: '/acct/auth/login/oidc_error',
    REDIRECT: '/acct/auth/login/redirect',
    FORGOT_PASSWORD: '/acct/auth/forgot_password',
    FORGOT_LOGIN: 'https://kipsu.com/how-can-we-help-you/',
    REQUEST_CONVERSATION: 'https://kipsu.com/request-a-conversation/',
    KIPSU_DASHBOARD: '/acct'
}
