import { MyThunkDispatch } from "../../store/rootStore"
import { submitUsername } from "../../store/login/actions"
import { loadWorkspaces, setSelectedWorkspace } from "../../store/workspace/actions"
import { Workspace } from "../../store/workspace/types"
import { isSingleSignOnUser, routeToOIDCRedirect } from "../../helpers/workspaceRouting"
import { connect } from "react-redux"
import RedirectView, { RedirectPayload } from './Redirect'
import { routes } from "../../shared/constants"
import { oidcSetConfig } from "../../store/oidc/actions"

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
    loadUser({ username }: RedirectPayload): Promise<string> {
        dispatch(submitUsername(username))

        return new Promise((resolve) => {
            dispatch(loadWorkspaces(username))
                .then((workspaces: Workspace[]) => {
                    const selectedWorkspace = workspaces.find((workspace: Workspace) => {
                        const url: URL = new URL(workspace.webUrl)
                        return url.host === window.location.host
                    })

                    if (selectedWorkspace) {
                        setSelectedWorkspace(selectedWorkspace)
                        if (isSingleSignOnUser(selectedWorkspace)) {
                            dispatch(oidcSetConfig(selectedWorkspace.identityProvider))
                            routeToOIDCRedirect(selectedWorkspace)
                        } else {
                            return resolve(routes.PASSWORD_FORM)
                        }
                    }

                    return resolve(routes.USERNAME_FORM)
                }).catch(() => {
                return resolve(routes.USERNAME_FORM)
            })
        })
    },
})

export default connect(null, mapDispatchToProps)(RedirectView)
