import React, { FunctionComponent } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import styles from './App.module.scss'
import './shared/utils.scss'
import { routes } from "./shared/constants"
import UsernameFormWrapper from "./components/UsernameForm/UsernameFormWrapper"
import PasswordFormWrapper from './components/PasswordForm/PasswordFormWrapper'
import WorkspacesFormWrapper from "./components/Workspaces/WorkspacesFormWrapper"
import LoginCallbackContainer from './components/LoginCallback/LoginCallbackContainer'
import OIDCCallbackErrorContainer from './components/OIDCCallbackError/OIDCCallbackErrorContainer'
import RedirectContainer from "./components/Redirect/RedirectContainer"
import UsernameAndWorkspaceProtectedRoute from './components/Routes/UsernameAndWorkspaceProtectedRoute'
import UsernameProtectedRoute from './components/Routes/UsernameProtectedRoute'
import SSOFormWrapper from './components/SSO/SSOForm/SSOFormWrapper'

const App: FunctionComponent = () => {
    return (
        <div className={styles.section}>
            <Switch>
                <UsernameProtectedRoute
                    path={routes.SELECT_WORKSPACE}
                    component={WorkspacesFormWrapper} />
                <UsernameAndWorkspaceProtectedRoute
                    path={routes.PASSWORD_FORM}
                    component={PasswordFormWrapper} />
                <Route path={routes.SSO_FORM}
                    component={SSOFormWrapper} />
                <Route path={routes.REDIRECT}
                    render={(props: RouteComponentProps) => <RedirectContainer {...props} />} />
                <Route path={routes.OIDC_CALLBACK}
                    component={LoginCallbackContainer} />
                <Route path={routes.OIDC_ERROR_PAGE}
                    component={OIDCCallbackErrorContainer} />
                <Route path={[routes.BASE, routes.USERNAME_FORM]}
                    render={(props: RouteComponentProps) => <UsernameFormWrapper {...props} />}
                />
            </Switch>
        </div>
    )
}

export default App
