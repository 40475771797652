import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client'
import { LoginResponse, OIDCConfig } from "../store/oidc/types"
import { routes } from "../shared/constants"

const KIPSU_OIDC_CONFIG = 'KIPSU_APP_SETTINGS'

const createOpenIdConnectClient = (config: OIDCConfig): UserManager => {
    const settings: UserManagerSettings = {
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
        authority: config.url,
        client_id: config.clientId,
        redirect_uri: `${window.location.protocol}//${window.location.host}${routes.OIDC_CALLBACK}`,
        response_type: "code",
        scope: "openid profile email",
        post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
        filterProtocolClaims: true,
        metadata: {
            issuer: config.url + "/",
            authorization_endpoint: config.authorizationEndpoint,
            token_endpoint: config.tokenEndpoint,
            userinfo_endpoint: config.url + "/userinfo",
            end_session_endpoint: config.url + "/v2/logout",
            jwks_uri: config.jwksEndpoint,
        }
    }

    localStorage.setItem(KIPSU_OIDC_CONFIG, JSON.stringify(config))
    return new UserManager(settings)
}

export const signInRedirect = (config: OIDCConfig): Promise<any> => {
    return createOpenIdConnectClient(config).signinRedirect()
        .catch((reason) => {
            console.error('Redirect failed', reason)
        })
}

export const loginListener = (): Promise<LoginResponse> => {
    let config = localStorage.getItem(KIPSU_OIDC_CONFIG)
    if (!config) {
        return new Promise((res, rej) => rej())
    }
    const parsedConfig = JSON.parse(config)
    if (!parsedConfig) {
        return new Promise((res, rej) => rej())
    }

    return createOpenIdConnectClient(parsedConfig).signinRedirectCallback()
}
