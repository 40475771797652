import { MyThunkDispatch, SingleLoginStore } from "../../store/rootStore"
import { connect } from "react-redux"
import WorkspacesForm from "./WorkspacesForm"
import {
    isSingleSignOnUser, routeToDifferenceKipsuInstance,
    routeToOIDCRedirect,
    shouldRouteToDifferentInstance
} from "../../helpers/workspaceRouting"
import { Workspace } from "../../store/workspace/types"
import { RouteComponentProps } from "react-router"
import { setSelectedWorkspace } from "../../store/workspace/actions"
import { routes } from "../../shared/constants"
import { oidcSetConfig } from "../../store/oidc/actions"

const mapStateToProps = (state: SingleLoginStore) => ({
    selectedWorkspace: state.workspace.selectedWorkspace
        ? {
            label: state.workspace.selectedWorkspace.workspaceName,
            value: state.workspace.selectedWorkspace.workspaceName
        }
        : null,
    username: state.login.username,
    workspaces: state.workspace.workspaces,
})


export interface OnWorkspaceSelectParams {
    selected: Workspace,
    username: string,
    router: RouteComponentProps
}

const mapDispatchToProps = (dispatch: MyThunkDispatch) => {
    return {
        onSelect: ({ selected, username, router }: OnWorkspaceSelectParams) => {
            dispatch(setSelectedWorkspace(selected))

            if (shouldRouteToDifferentInstance(selected)) {
                routeToDifferenceKipsuInstance(selected, username)
            } else if (isSingleSignOnUser(selected)) {
                dispatch(oidcSetConfig(selected.identityProvider))
                routeToOIDCRedirect(selected)
            } else {
                router.history.push(routes.PASSWORD_FORM)
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacesForm)
