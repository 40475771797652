import axios from "axios"
import { Action } from "redux"
import { ThunkAction } from "redux-thunk"
import { MyThunkDispatch, SingleLoginStore } from "../rootStore"
import {
    OIDC_SET_CONFIG,
    OIDC_SET_USER,
    OIDC_SUBMIT_FAILURE,
    OIDC_SUBMIT_REQUEST,
    LoginResponse,
    OIDCConfig,
    OIDCSetConfigAction,
    OIDCSubmitFailureAction
} from "./types"
import { Workspace } from "../workspace/types"
import { KIPSU_SELECTED_WORKSPACE } from "../../helpers/workspaceRouting"

type MyExtraArg = undefined;
type MyThunkResult<R> = ThunkAction<R, SingleLoginStore, MyExtraArg, Action<any>>

export const oidcSetConfig = (config: OIDCConfig | null): OIDCSetConfigAction => ({
    type: OIDC_SET_CONFIG,
    config
})

export const oidcSubmitRequest = (user: LoginResponse): MyThunkResult<Promise<Workspace | null>> => (dispatch: MyThunkDispatch) => {

    dispatch({ type: OIDC_SET_USER, user: user })
    dispatch({ type: OIDC_SUBMIT_REQUEST })

    const url = process.env.REACT_APP_OIDC_LOGIN_ROUTE || ''
    const workspace: string | null = localStorage.getItem(KIPSU_SELECTED_WORKSPACE)
    const parsedWorkspace: Workspace | null = workspace ? JSON.parse(workspace) : null
    const identityProviderId = parsedWorkspace && parsedWorkspace.identityProvider ? parsedWorkspace.identityProvider.identityProviderId : null

    return new Promise((resolve, reject) => {
        return axios.post(`${url}?oauth=true`, {
            jwt: user.id_token,
            identityProviderId,
            jsonErrorResponse: true
        }).then(() => {
            return resolve(parsedWorkspace)
        }).catch(reject)
    })
}

export const oidcSubmitFailure = (error: string): OIDCSubmitFailureAction => ({
    type: OIDC_SUBMIT_FAILURE,
    error
})
