import React, { Component as ReactComponent, FunctionComponent } from "react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import { routes } from "../../shared/constants"
import { Workspace } from "../../store/workspace/types"
import { SingleLoginStore } from "../../store/rootStore"
import { connect } from "react-redux"

interface Props {
    component: ReactComponent,
    workspaces: Workspace[],
    username: string
}

const ProtectedRoute: FunctionComponent<Props> = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props: RouteComponentProps) => (
            rest.username && rest.workspaces.length
                // @ts-ignore
                ? <Component {...props} />
                : <Redirect to={routes.USERNAME_FORM} />
        )}
    />
)

const mapStateToProps = (state: SingleLoginStore) => ({
    workspaces: state.workspace.workspaces,
    username: state.login.username
})

const UsernameAndWorkspaceProtectedRoute: FunctionComponent<any> = connect(mapStateToProps)(ProtectedRoute)
export default UsernameAndWorkspaceProtectedRoute
