export interface IdentityProvider {
    clientId: string,
    identityProviderId: string,
    authorizationEndpoint: string,
    tokenEndpoint: string,
    jwksEndpoint: string,
    url: string
}

export interface Workspace {
    baseUrl: string,
    identityProvider: IdentityProvider | null,
    loginApiKey: string,
    pusherApiKey: string,
    workspaceName: string,
    webUrl: string,
}

// Workspace Action Constants
export const FETCH_WORKSPACES_FAILURE = 'FETCH_WORKSPACES_FAILURE'
export const FETCH_WORKSPACES_REQUEST = 'FETCH_WORKSPACES_REQUEST'
export const FETCH_WORKSPACES_SUCCESS = 'FETCH_WORKSPACES_SUCCESS'
export const SET_SELECTED_WORKSPACE = 'SET_SELECTED_WORKSPACE'

// Workspace Action Types
export interface FetchWorkspacesRequest {
    type: typeof FETCH_WORKSPACES_REQUEST
}

export interface FetchWorkspacesFailure {
    type: typeof FETCH_WORKSPACES_FAILURE
    error: string
}

export interface FetchWorkspacesSuccess {
    type: typeof FETCH_WORKSPACES_SUCCESS,
    workspaces: Workspace[]
}

export interface SetSelectedWorkspaceAction {
    type: typeof SET_SELECTED_WORKSPACE,
    selectedWorkspace: Workspace
}

export type WorkspaceActionTypes =
    FetchWorkspacesRequest
    | FetchWorkspacesFailure
    | FetchWorkspacesSuccess
    | SetSelectedWorkspaceAction

