import React, { Component } from "react"
import { Redirect } from "react-router"
import Main from "../Main/Main"
import Loader from "../Loader/Loader"
import { routes } from "../../shared/constants"

export interface RedirectPayload {
    username: string,
}

interface Props {
    loadUser(payload: RedirectPayload): Promise<string>
}

interface State {
    route: string | null
}

class RedirectView extends Component<Props, State> {

    public constructor(props: Props) {
        super(props)
        this.state = {
            route: null
        }
    }

    public componentDidMount(): void {
        const params = new URLSearchParams(window.location.search)
        const username = params.get('username')

        if (username) {
            this.props.loadUser({ username })
                .then((route: string) => {
                    this.setState({ route: route })
                })
        } else {
            this.setState({ route: routes.USERNAME_FORM })
        }

    }

    public render() {
        const { route } = this.state

        return route
            ? <Redirect to={route} />
            : (
                <Main centeredWithoutStyles>
                    <Loader isLoading={true} />
                </Main>
            )
    }
}


export default RedirectView
