import React from 'react'
import { RouterProps, Redirect } from "react-router"
import { routes } from "../../shared/constants"
import { loginListener } from "../../helpers/ocid"
import { LoginResponse } from "../../store/oidc/types"
import Loader from "../Loader/Loader"
import Main from "../Main/Main"

interface Props {
    error: string
    submitResponse: (user: LoginResponse) => void
    handleError: (error: string) => void
}

export default class LoginCallback extends React.Component<Props & RouterProps> {
    public componentDidMount(): void {
        const { submitResponse, handleError } = this.props

        loginListener().then((user: LoginResponse) => {
            submitResponse(user)
        }).catch((error: { error: string }) => {
            handleError(error.error)
        })
    }

    public render() {
        return !!this.props.error ? <Redirect to={routes.OIDC_ERROR_PAGE} /> : (
            <Main centeredWithoutStyles>
                <Loader isLoading={true} />
            </Main>
        )
    }
}
