import React, { FunctionComponent } from 'react'
import styles from './Main.module.scss'

interface Props {
    centeredWithoutStyles?: boolean
}

const Main: FunctionComponent<Props> = ({ children, centeredWithoutStyles = false }) => (
    <section role="main"
             className={centeredWithoutStyles ? styles.centeredWithoutStyles : styles.main}
    >{children}</section>
)

export default Main
