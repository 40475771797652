import React, { FunctionComponent, useState } from 'react';
import SSOFilter from './SSOFilter';
import { SSOProperty } from '../../../store/sso/types';
import styles from './SSOFilterContainer.module.scss';

interface Props {
  properties: SSOProperty[];
}

const SSOFilterContainer: FunctionComponent<Props> = ({ properties }) => {
  const [searchField, setSearchField] = useState('');

  const filteredProperties = properties.filter((property) => {
    return property.brand.toLowerCase().includes(searchField.toLowerCase());
  });

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchField(e.target.value);
  };

  function searchList() {
    return <SSOFilter properties={filteredProperties} />;
  }

  return (
    <section className={styles.container}>
      <div className={styles.search}>
        <h3 className={styles.title}>Select Your SSO Organization</h3>
        <i className="kip-search" />
        <input
          className={styles.input}
          type="text"
          placeholder="Search Organizations"
          onChange={handleChange}
        />
      </div>
      {searchList()}
    </section>
  );
};

export default SSOFilterContainer;
