import React, { FunctionComponent } from "react"
import styles from './Details.module.scss'
import { routes } from '../../shared/constants'

interface Props {
    showSalesLink?: boolean
}

const Details: FunctionComponent<Props> = ({ children, showSalesLink = false }) => {
    return (
        <section className={styles.details}>
            <header className={styles.header}>
                <h2>Can't log in?</h2>
                <p>Don't worry, we're here to help.</p>
            </header>
            <section>
                {children}
            </section>
            <footer className={styles.footer}>
                <ul>
                    <li>
                        <a href="https://kipsu.com">kipsu.com</a>
                    </li>
                    <li>
                        <a href="mailto:support@kipsu.com">support@kipsu.com</a>
                    </li>
                    <li>
                        <a href="tel:+1612-503-4000">1.612.503.4000</a>
                    </li>
                </ul>
                { showSalesLink ?
                    <ul>
                        <li>Interested in getting started with Kipsu? </li>
                    </ul> : null }
                { showSalesLink ?
                    <ul>
                        <li>
                            <a href={routes.REQUEST_CONVERSATION}>Contact our sales team.</a>
                        </li>
                    </ul> : null }
            </footer>
        </section>
    )
}

export default Details
