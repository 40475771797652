import React, { FunctionComponent } from 'react';
import { SSOProperty } from '../../../store/sso/types';
import { Link } from 'react-router-dom';
import styles from './SSOButton.module.scss';

interface Props {
  property: SSOProperty;
}

const SSOButton: FunctionComponent<Props> = ({ property }) => {
  return (
    <Link
      className={styles.link}
      to={{ pathname: property.redirect }}
      target="_blank"
    >
      <div className={styles.container}>
        <div className={styles.image}>
          <img src={property.imagePath} alt={property.brand} />
        </div>
        <div className={styles.text}>{property.brand}</div>
      </div>
    </Link>
  );
};

export default SSOButton;
