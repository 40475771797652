import React, { Component, FormEvent } from "react"
import FormWrapper from "../Form/FormWrapper"
import FormHeader from "../Form/FormHeader"
import styles from '../PasswordForm/PasswordForm.module.scss'
import { Link, RouteComponentProps } from "react-router-dom"
import { routes } from "../../shared/constants"
import Dropdown, { SelectedOption } from '../Dropdown/Dropdown'
import KButton, { KButtonTypes } from "../KButton/KButton"
import { Workspace } from "../../store/workspace/types"
import { OnWorkspaceSelectParams } from "./WorkspacesFormContainer"
import Loader from "../Loader/Loader"

interface Props {
    selectedWorkspace: SelectedOption | null,
    username: string,
    workspaces: Workspace[],

    onSelect(payload: OnWorkspaceSelectParams): void,
}

interface State {
    error: string,
    isLoading: boolean,
    selectedWorkspace: SelectedOption | null,
}

class WorkspacesForm extends Component<Props & RouteComponentProps, State> {

    public constructor(props: Props & RouteComponentProps) {
        super(props)
        this.state = {
            error: '',
            isLoading: false,
            selectedWorkspace: props.selectedWorkspace,
        }
    }

    public render() {
        const { workspaces } = this.props

        return (
            <FormWrapper
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault()

                    const selectedWorkspace = workspaces.find((workspace: Workspace) => (
                        this.state.selectedWorkspace && workspace.workspaceName === this.state.selectedWorkspace.value
                    ))

                    if (!selectedWorkspace) {
                        this.setState({ error: 'Please select a workspace.' })
                        return
                    }

                    this.setState({ error: '', isLoading: true })

                    this.props.onSelect({
                        router: this.props,
                        selected: selectedWorkspace,
                        username: this.props.username,
                    })

                    this.setState({ isLoading: false })
                }}
            >
                <FormHeader />
                <Dropdown
                    label={"Choose a Workspace"}
                    placeholder={'Select'}
                    disabled={false}
                    id={'select-workspace'}
                    subtext={`Logging in as ${this.props.username}`}
                    options={workspaces.map((workspace: Workspace) => (
                        { label: workspace.workspaceName, value: workspace.workspaceName }
                    ))}
                    error={this.state.error}
                    selectedOption={this.state.selectedWorkspace}
                    onChange={(selected: SelectedOption) => {
                        this.setState({ selectedWorkspace: selected, error: '' })
                    }}
                />
                <footer className={styles.footer}>
                    <Link to={routes.USERNAME_FORM}
                          aria-label="Return to username form">
                        <span className={styles.background} aria-hidden="true"> </span>
                        <span className={styles.link}>Back</span>
                    </Link>
                    <div className={styles.submitContainer}>
                        <Loader isLoading={this.state.isLoading} />
                        <KButton type={KButtonTypes.SUBMIT}>Next</KButton>
                    </div>
                </footer>
            </FormWrapper>
        )
    }
}

export default WorkspacesForm
