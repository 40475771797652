import {
    LoginActionTypes,
    PASSWORD_ERROR,
    SUBMIT_LOGIN_FAILURE,
    SUBMIT_LOGIN_REQUEST,
    SUBMIT_LOGIN_SUCCESS,
    SUBMIT_USERNAME,
    USERNAME_ERROR
} from "./types"

export interface LoginFormState {
    username: string,
    usernameError: string,
    password: string,
    passwordError: string,
    isLoading: boolean
}

const initialState: LoginFormState = {
    username: '',
    usernameError: '',
    password: '',
    passwordError: '',
    isLoading: false
}

export default function loginForm(state: LoginFormState = initialState, action: LoginActionTypes) {
    switch (action.type) {
        case SUBMIT_USERNAME:
            return { ...state, username: action.username }
        case USERNAME_ERROR:
            return { ...state, usernameError: action.error }
        case PASSWORD_ERROR:
            return { ...state, passwordError: action.error, isLoading: false }
        case SUBMIT_LOGIN_REQUEST:
            return { ...state, isLoading: true }
        case SUBMIT_LOGIN_FAILURE:
            return { ...state, passwordError: action.error, isLoading: false }
        case SUBMIT_LOGIN_SUCCESS:
            return { ...state, isLoading: false }
        default:
            return state
    }
}
