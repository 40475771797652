import React, { ChangeEvent, FunctionComponent } from 'react'
import './KInput.scss'

interface Params {
    label?: string,
    prependText?: string,
    appendText?: string,
    error?: string,
    onRefChange?: (node: HTMLInputElement) => void
    id: string,
    subtext?: string,
    type?: string,
    value: string,
    onChange: (value: string) => void,
    autocomplete?: string,
    placeholder: string,
}

const PrependButton = ({ prependText }: { prependText: string }) => (
    <button
        className="k-input__button k-input__prepend">{prependText}
    </button>
)

const AppendButton = ({ appendText }: { appendText: string }) => (
    <button
        onClick={() => {
        }}
        className="k-input__button k-input__append">{appendText}
    </button>
)

const KInput: FunctionComponent<Params> = ({ label, prependText, appendText, error, onRefChange, id, subtext, type, value, onChange, autocomplete, placeholder }) => {
    const inputRef = React.useRef(null);
    React.useEffect(()=>{
        inputRef.current.focus();
    }, []);
    return (
        <div className={'k-input' + (error && error.length ? ' k-input--errors' : '')}>
            <label className="k-input__label" htmlFor={id}>{label}
                {subtext && <small className="k-input__subtext">{subtext}</small>}
            </label>
            <div className="k-input__container">
                {prependText && <PrependButton prependText={prependText} />}
                <input type={type || 'text'}
                       id={id}
                       className="k-input__input"
                       value={value}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                       aria-invalid={!!(error && error.length)}
                       autoComplete={autocomplete}
                       placeholder={placeholder}
                       ref={inputRef}
                />
                {appendText && <AppendButton appendText={appendText} />}
            </div>
            <div className="k-input__error">
                {error && error.length ? <span role="alert">{error}</span> : null}
            </div>
        </div>
    )
}

export default KInput
