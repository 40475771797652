import { MyThunkDispatch, SingleLoginStore } from "../../store/rootStore"
import { LoginResponse } from "../../store/oidc/types"
import { connect } from "react-redux"
import LoginCallback from "./LoginCallback"
import { oidcSubmitFailure, oidcSubmitRequest } from "../../store/oidc/actions"

const mapStateToProps = (state: SingleLoginStore) => {
    return {
        error: state.oidc.error,
    }
}

const mapDispatchToProps = (dispatch: MyThunkDispatch) => {
    return {
        submitResponse: (user: LoginResponse) => {
            dispatch(oidcSubmitRequest(user)).then((workspace) => {
                if (workspace) {
                    window.location.href = workspace.webUrl
                }
            }).catch(e => {
                dispatch(oidcSubmitFailure(e))
            })
        },
        handleError: (error: string) => {
            dispatch(oidcSubmitFailure(error))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginCallback)
