import { Action, applyMiddleware, combineReducers, createStore } from "redux"
import login, { LoginFormState } from "./login/reducers"
import workspace, { WorkspaceState } from "./workspace/reducers"
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from "redux-thunk"
import { createLogger } from "redux-logger"
import oidc, { OIDCState } from "./oidc/reducers"
import { LoginActionTypes } from "./login/types"
import { WorkspaceActionTypes } from "./workspace/types"
import { OIDCActionTypes } from "./oidc/types"

export interface SingleLoginStore {
    login: LoginFormState,
    workspace: WorkspaceState,
    oidc: OIDCState
}

type MyExtraArg = undefined;
export type MyThunkDispatch = ThunkDispatch<SingleLoginStore, MyExtraArg, Action>;
const loggerMiddleware = createLogger()

type SingleLoginThunkMiddleware = ThunkMiddleware<SingleLoginStore, LoginActionTypes | WorkspaceActionTypes | OIDCActionTypes>


const reducers = combineReducers({
    login,
    workspace,
    oidc
})

const middleware = () => {
    if (process.env.NODE_ENV !== 'production') {
        return applyMiddleware(
            thunkMiddleware as SingleLoginThunkMiddleware,
            loggerMiddleware
        )
    }

    return applyMiddleware(
        thunkMiddleware as SingleLoginThunkMiddleware,
    )
}

const store = createStore(
    reducers,
    middleware()
)

export default store
