import React, { FunctionComponent } from 'react'
import styles from './ButtonLink.module.scss'

interface Params {
    to: string
}

const ButtonLink: FunctionComponent<Params> = ({ children, to }) => (
    <a href={to} className={styles.link}>{children}</a>
)

export default ButtonLink
