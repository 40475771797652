import React, { CSSProperties, FunctionComponent } from "react"
import Select from 'react-select'
import { Theme } from "react-select/src/types"
import styles from './Dropdown.module.scss'

interface Option {
    label: string,
    value: string,
}

interface Props {
    options: Option[],
    id: string,
    disabled: false,
    placeholder: string,
    selectedOption: SelectedOption | null,
    onChange: (selected: SelectedOption) => void
    label: string,
    subtext?: string,
    error: string
}

export interface SelectedOption {
    label: string,
    value: string,
}

type DropdownState = {
    isFocused: boolean
}

const KDropdown: FunctionComponent<Props> = (props: Props) => {
    const control = (base: CSSProperties, state: DropdownState) => {
        const addedStyle = getSelectControlStyleAdded(state.isFocused, !!(props.error && props.error.length))
        return { ...base, ...addedStyle }
    }

    return (
        <section>
            <label className={styles.label} htmlFor={props.id}>{props.label}
                {props.subtext && <small className={styles.subtext}>{props.subtext}</small>}
            </label>
            <Select
                //@ts-ignore
                value={props.selectedOption}
                placeholder={props.placeholder}
                isClearable={false}
                isSearchable={false}
                theme={theme}
                styles={{ ...style, control }}

                // @ts-ignore
                onChange={(value) => props.onChange(value)}
                options={props.options} />
            <div className={styles.errors}>
                {props.error && props.error.length ? <span role="alert">{props.error}</span> : null}
            </div>
        </section>

    )
}

export default KDropdown

const theme = (theme: Theme) => ({
    ...theme,
    borderRadius: 5,
    spacing: {
        ...theme.spacing,
        menuGutter: 2
    }
})

const style = {
    dropdownIndicator: (base: CSSProperties) => {
        return { ...base, color: 'hsl(0, 0%, 58%)', ...{ ':hover': 'hsl(0, 0%, 55%)' } }
    },
    indicatorSeparator: (base: CSSProperties) => {
        return { ...base, width: 0 }
    },
    option: (base: CSSProperties, state: DropdownState) => {
        const customStyles = {
            fontSize: '0.95em',
            color: '#444444',
            fontFamily: '\'Helvetica Neue\', Helvetica, Arial, sans-serif',
            backgroundColor: '#ffffff',
        }

        if (state.isFocused) {
            const focus = {
                backgroundColor: '#f1f1f2',
                color: '#195cab'
            }
            return { ...base, ...customStyles, ...focus }
        }
        return { ...base, ...customStyles }
    }
}

const getSelectControlStyleAdded = (isFocused: boolean, errorExists: boolean)
    : CSSProperties | { [key: string]: string | number } => {
    if (errorExists) {
        if (isFocused) {
            return {
                boxShadow: '0 0 0 2px #cd4c1d',
                borderColor: '#cd4c1d',
                '&:hover': {
                    borderColor: '#cd4c1d',
                }
            }

        }
        return {
            border: '1px solid #cd4c1d',
            '&:hover': {
                border: '1px solid #cd4c1d'
            }
        }
    }

    if (isFocused) {
        return {
            boxShadow: '0 0 0 2px hsl(195, 43%, 59%)',
            borderColor: 'hsl(195, 43%, 59%)',
            '&:hover': {
                borderColor: 'hsl(195, 43%, 59%)',
            }
        }

    }
    return { border: '1px solid hsl(0, 0%, 58%)' }
}
