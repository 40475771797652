import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Root from './Root'
import store from './store/rootStore'

ReactDOM.render(
    <Root store={store} />,
    document.getElementById('root')
)
