import { User } from "oidc-client"

export interface OIDCConfig {
    identityProviderId: string,
    clientId: string,
    authorizationEndpoint: string,
    tokenEndpoint: string,
    jwksEndpoint: string,
    url: string
}

export interface LoginResponse extends User {}

// OIDC Action Constants
export const OIDC_SET_CONFIG = 'OIDC_SET_CONFIG'
export const OIDC_SET_USER = 'OIDC_SET_USER'
export const OIDC_REDIRECT = 'OIDC_REDIRECT'
export const OIDC_SUBMIT_REQUEST = 'OIDC_SUBMIT_REQUEST'
export const OIDC_SUBMIT_FAILURE = 'OIDC_SUBMIT_FAILURE'

// OIDC Action Interfaces
export interface OIDCSetConfigAction {
    type: typeof OIDC_SET_CONFIG,
    config: OIDCConfig | null
}

export interface OIDCRedirectAction {
    type: typeof OIDC_REDIRECT
}

export interface OIDCSubmitRequestAction {
    type: typeof OIDC_SUBMIT_REQUEST,
    user: LoginResponse
}

export interface OIDCSubmitFailureAction {
    type: typeof OIDC_SUBMIT_FAILURE,
    error: string
}

export type OIDCActionTypes
    = OIDCSetConfigAction
    | OIDCRedirectAction
    | OIDCSubmitRequestAction
    | OIDCSubmitFailureAction
