// Login Action Constants
export const PASSWORD_ERROR = 'PASSWORD_ERROR'
export const SUBMIT_USERNAME = 'SUBMIT_USERNAME'
export const USERNAME_ERROR = 'USERNAME_ERROR'
export const SUBMIT_LOGIN_REQUEST = 'SUBMIT_LOGIN_REQUEST'
export const SUBMIT_LOGIN_FAILURE = 'SUBMIT_LOGIN_FAILURE'
export const SUBMIT_LOGIN_SUCCESS = 'SUBMIT_LOGIN_SUCCESS'

// Login Action Interfaces
export interface SubmitUsernameAction {
    type: typeof SUBMIT_USERNAME,
    username: string
}

export interface UsernameErrorAction {
    type: typeof USERNAME_ERROR,
    error: string
}

export interface PasswordErrorAction {
    type: typeof PASSWORD_ERROR,
    error: string
}

export interface SubmitLoginRequest {
    type: typeof SUBMIT_LOGIN_REQUEST
}

export interface SubmitLoginFailure {
    type: typeof SUBMIT_LOGIN_FAILURE,
    error: string
}

export interface SubmitLoginSuccess {
    type: typeof SUBMIT_LOGIN_SUCCESS
}

export type LoginActionTypes =
    SubmitUsernameAction
    | UsernameErrorAction
    | PasswordErrorAction
    | SubmitLoginRequest
    | SubmitLoginFailure
    | SubmitLoginSuccess
