import React, { FunctionComponent } from 'react';
import Main from '../../Main/Main';
import Details from '../../Details/Details';
import ButtonLink from '../../ButtonLink';
import { routes } from '../../../shared/constants';
import SSOForm from './SSOForm';

const SSOFormWrapper: FunctionComponent = () => (
  <Main>
    <SSOForm />
    <Details>
      <ButtonLink to={routes.FORGOT_LOGIN}>Contact Support</ButtonLink>
    </Details>
  </Main>
);

export default SSOFormWrapper;
