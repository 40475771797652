import { connect } from "react-redux"
import PasswordForm from './PasswordForm'
import { MyThunkDispatch, SingleLoginStore } from "../../store/rootStore"
import { passwordError, submitPassword } from "../../store/login/actions"
import { RouterProps } from "react-router"
import { Workspace } from "../../store/workspace/types"

export interface PasswordOnSubmitParams {
    password: string,
    router: RouterProps
    selectedWorkspace: Workspace | null,
    username: string,
}

const mapStateToProps = (state: SingleLoginStore) => {
    return {
        error: state.login.passwordError,
        isLoading: state.login.isLoading,
        selectedWorkspace: state.workspace.selectedWorkspace,
        multipleWorkspaces: state.workspace.workspaces.length > 1,
        password: state.login.password,
        username: state.login.username,
    }
}

const mapDispatchToProps = (dispatch: MyThunkDispatch) => {
    return {
        onSubmit: (payload: PasswordOnSubmitParams) => {
            dispatch(submitPassword(payload))
                .then(() => {
                    if (!payload.selectedWorkspace) {
                        dispatch(passwordError('We are unable to log you into Kipsu. Please contact Kipsu support.'))
                        return
                    }

                    window.location.href = payload.selectedWorkspace.webUrl
                })
                .catch(() => {})
        },
        onError: (error: string) => {
            dispatch(passwordError(error))
        },
        clearError: () => {
            dispatch(passwordError(''))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm)
