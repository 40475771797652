import React, { FC, ReactNode } from 'react'
import './KButton.scss'

type Props = {
    children: ReactNode
    disabled?: boolean,
    onClick?: () => void,
    type?: KButtonTypes | undefined,
    variant?: KButtonVariants,
}

export enum KButtonTypes {
    BUTTON = 'button',
    RESET = 'reset',
    SUBMIT = 'submit',
}

export enum KButtonVariants {
    DISABLED = 'k-button--disabled',
    PRIMARY = 'k-button--primary',
    SECONDAY = 'k-button--secondary',
    TERTIARY = 'k-button--tertiary',
    NEW = 'k-button--new'
}

const KButton: FC<Props> = ({onClick, disabled = false, type = undefined, variant, children}: Props) => (
    <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={'k-button ' + (variant || KButtonVariants.PRIMARY)}
    >{children}</button>
)

export default KButton
