import React, { FunctionComponent } from 'react';
import SSOButton from '../SSOButton/SSOButton';
import { SSOProperty } from '../../../store/sso/types';
import styles from './SSOFilterContainer.module.scss';

interface Props {
  properties: SSOProperty[];
}

const SSOFilter: FunctionComponent<Props> = ({ properties }) => {
  const filtered = properties.map((property) => (
    <SSOButton key={property.id} property={property} />
  ));

  return <div className={styles.ssoFilter}>{filtered}</div>;
};

export default SSOFilter;
