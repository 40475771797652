import React, { FunctionComponent } from 'react';
import UsernameFormContainer from './UsernameFormContainer';
import Details from '../Details/Details';
import Main from '../Main/Main';
import ButtonLink from '../ButtonLink';
import { RouteComponentProps } from 'react-router';
import { routes } from '../../shared/constants';

const UsernameFormWrapper: FunctionComponent<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  return (
    <Main>
      <UsernameFormContainer {...props} />
      <Details showSalesLink>
        <ButtonLink to={routes.FORGOT_LOGIN}>Contact Support</ButtonLink>
      </Details>
    </Main>
  );
};

export default UsernameFormWrapper;
