import React, { Component, FormEvent } from 'react';
import KButton, { KButtonTypes } from '../KButton/KButton';
import KInput from '../KInput/KInput';
import FormWrapper from '../Form/FormWrapper';
import FormHeader from '../Form/FormHeader';
import { RouteComponentProps } from 'react-router';
import Loader from '../Loader/Loader';
import styles from './UsernameForm.module.scss';
import { OnSubmitParams } from './UsernameFormContainer';
import { routes } from '../../shared/constants';

interface Props {
  isLoading: boolean;
  username: string;
  error: string;

  onError(error: string): void;

  clearError(): void;

  onSubmit(payload: OnSubmitParams): void;
}

interface State {
  input: string;
}

class UsernameForm extends Component<Props & RouteComponentProps, State> {
  public constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      input: props.username,
    };
  }

  public render() {
    let error: string = this.props.error || '';
    if (this.props.location.search.includes('disabled=1')) {
      error = 'This account is disabled.';
    }
    return (
      <FormWrapper
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          if (this.state.input == null || !this.state.input.trim()) {
            this.props.onError('Please enter a username');
            return;
          }
          this.props.clearError();
          this.props.onSubmit({
            username: this.state.input,
            router: this.props,
          });
        }}
      >
        <FormHeader />
        <KInput
          value={this.state.input}
          onChange={(value: string) => {
            this.setState({ input: value });
          }}
          id="username"
          error={error}
          autocomplete="username"
          placeholder="Login with Username or Email"
        />
        <KButton type={KButtonTypes.SUBMIT}>Next</KButton>
        <div>
          <p className={styles.p}>
            <span className={styles.span}>OR</span>
          </p>
        </div>
        <a href={routes.SSO_FORM} className={styles.button}>
          Login with SSO
        </a>
        <footer className={styles.footer}>
          <Loader isLoading={this.props.isLoading} />
        </footer>
      </FormWrapper>
    );
  }
}

export default UsernameForm;
