import axios, { AxiosError, AxiosResponse } from 'axios'
import {
    PASSWORD_ERROR,
    PasswordErrorAction,
    SUBMIT_LOGIN_FAILURE,
    SUBMIT_LOGIN_REQUEST,
    SUBMIT_LOGIN_SUCCESS,
    SUBMIT_USERNAME,
    SubmitUsernameAction,
    USERNAME_ERROR,
    UsernameErrorAction
} from "./types"
import { MyThunkDispatch } from "../rootStore"
import { ThunkAction } from "redux-thunk"
import { Action } from "redux"

type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkResult<R> = ThunkAction<R, MyRootState, MyExtraArg, Action<any>>

export const submitUsername = (username: string): SubmitUsernameAction => ({
    type: SUBMIT_USERNAME,
    username
})

export const usernameError = (error: string): UsernameErrorAction => ({
    type: USERNAME_ERROR,
    error
})

export interface SubmitLoginRequestParams {
    username: string,
    password: string
}

export const submitPassword = (payload: SubmitLoginRequestParams): MyThunkResult<Promise<void>> => (dispatch: MyThunkDispatch) => {

    dispatch({ type: SUBMIT_LOGIN_REQUEST })

    const url: string | undefined = process.env.REACT_APP_LOGIN_ROUTE

    if (!url) {
        throw new Error('A login route was not set')
    }

    return axios.post(url, {
        email: payload.username,
        password: payload.password,
        jsonErrorResponse: true
    })
        .then((response: AxiosResponse<{ success: boolean }>) => {
            if (response.data.success) {
                dispatch({ type: SUBMIT_LOGIN_SUCCESS })
            }
        })
        .catch(({ response }: AxiosError<{ success: boolean, error: string }>) => {
            const error: string = response && response.data && response.data.error
                ? response.data.error
                : 'An error occurred. Please try again.'
            dispatch({ type: SUBMIT_LOGIN_FAILURE, error })
            throw new Error('An error occurred')
        })
}


export const passwordError = (error: string): PasswordErrorAction => ({
    type: PASSWORD_ERROR,
    error
})
