import React, { FunctionComponent } from "react"
import styles from './FormHeader.module.scss'

const FormHeader: FunctionComponent = () => (
    <header>
        <img src={process.env.PUBLIC_URL + '/Logo.svg'} alt="" className={styles.logo} />
        <h1 className="sr-only">Kipsu</h1>
    </header>
)


export default FormHeader
