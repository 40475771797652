import React, { FunctionComponent } from 'react'
import Main from "../Main/Main"
import styles from './OIDCCallbackError.module.scss'

interface Props {
    error: string
}

const OIDCCallbackError: FunctionComponent<Props> = () => (
    <Main centeredWithoutStyles>
        <section className={styles.error}>
            <header className={styles.header}>
                <h1 className="sr-only">Kipsu</h1>
            </header>
            <section className={styles.body}>
                <h2>Uh-oh! An Error Occurred.</h2>
                <p>Please contact Kipsu Support for more details.</p>
            </section>
        </section>
    </Main>
)

export default OIDCCallbackError
