import axios, { AxiosResponse } from 'axios'
import { Action } from "redux"
import {
    FETCH_WORKSPACES_FAILURE,
    FETCH_WORKSPACES_REQUEST,
    FETCH_WORKSPACES_SUCCESS,
    FetchWorkspacesFailure,
    FetchWorkspacesSuccess,
    SET_SELECTED_WORKSPACE,
    SetSelectedWorkspaceAction,
    Workspace
} from "./types"
import { ThunkAction } from "redux-thunk"

type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkResult<R> = ThunkAction<R, MyRootState, MyExtraArg, Action>


export const loadWorkspaces = (username: string): MyThunkResult<Promise<Workspace[]>> => (dispatch) => {
    dispatch({ type: FETCH_WORKSPACES_REQUEST })

    const url: string = process.env.REACT_APP_SINGLE_LOGIN_SERVICE_URL || ''

    return axios.get(`${url}${encodeURI(username)}`, {timeout: 30000})
        .then((response: AxiosResponse<Workspace[]>) => {
            const workspaces: Workspace[] = response.data || []
            if (!workspaces.length) {
                throw new Error('Account not found. Please try again.')
            }
            dispatch(fetchWorkspacesSuccess(workspaces))
            return workspaces
        }).catch(error => {
	    const message = error.response ? error.response.data.error : error.message
	    throw new Error(message)
	})
}

export const fetchWorkspacesError = (error: string): FetchWorkspacesFailure => ({
    type: FETCH_WORKSPACES_FAILURE,
    error
})

export const fetchWorkspacesSuccess = (workspaces: Workspace[]): FetchWorkspacesSuccess => ({
    type: FETCH_WORKSPACES_SUCCESS,
    workspaces
})

export const setSelectedWorkspace = (selectedWorkspace: Workspace): SetSelectedWorkspaceAction => ({
    type: SET_SELECTED_WORKSPACE,
    selectedWorkspace
})
