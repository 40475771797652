import {
    FETCH_WORKSPACES_FAILURE,
    FETCH_WORKSPACES_REQUEST,
    FETCH_WORKSPACES_SUCCESS,
    SET_SELECTED_WORKSPACE,
    Workspace,
    WorkspaceActionTypes
} from "./types"

export interface WorkspaceState {
    isLoading: boolean,
    selectedWorkspace: Workspace | null,
    workspaces: Workspace[],
    error: string
}

const initialState: WorkspaceState = {
    isLoading: false,
    selectedWorkspace: null,
    workspaces: [],
    error: ''
}

export default function workspace(state: WorkspaceState = initialState, action: WorkspaceActionTypes): WorkspaceState {
    switch (action.type) {
        case FETCH_WORKSPACES_REQUEST:
            return { ...state, isLoading: true }
        case FETCH_WORKSPACES_FAILURE:
            return { ...state, error: action.error, isLoading: false }
        case FETCH_WORKSPACES_SUCCESS:
            return { ...state, workspaces: action.workspaces, isLoading: false }
        case SET_SELECTED_WORKSPACE:
            return { ...state, selectedWorkspace: action.selectedWorkspace }
        default:
            return state
    }
}
