import { connect } from "react-redux"
import { MyThunkDispatch, SingleLoginStore } from "../../store/rootStore"
import OIDCCallbackError from "./OIDCCallbackError"

const mapStateToProps = (state: SingleLoginStore) => {
    return {
        error: state.oidc.error
    }
}

const mapDispatchToProps = (dispatch: MyThunkDispatch) => {
    return {
        //
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OIDCCallbackError)
