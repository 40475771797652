import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from "react-router"
import Main from "../Main/Main"
import WorkspacesFormContainer from "./WorkspacesFormContainer"
import Details from "../Details/Details"
import ButtonLink from "../ButtonLink"
import { routes } from "../../shared/constants"


const WorkspacesFormWrapper: FunctionComponent<RouteComponentProps> = (props) => (
    <Main>
        <WorkspacesFormContainer {...props}/>
        <Details>
            <ButtonLink to={routes.FORGOT_LOGIN}>Contact Support</ButtonLink>
        </Details>
    </Main>
)

export default WorkspacesFormWrapper
