import React, { FunctionComponent } from 'react'
import { Provider } from 'react-redux'
import App from "./App"
import { BrowserRouter } from "react-router-dom"

interface RootProps {
    store: any
}

const Root: FunctionComponent<RootProps> = ({ store }) => (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

export default Root
